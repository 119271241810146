@import '../../styles/button.scss';
@import '../../styles/variable.scss';
@import '../../styles/grid.scss';
@import './footer.scss';
@import './header.scss';

* {
	box-sizing: border-box;
}

html {
	font-size: 16px;
	line-height: 1.5;
	&.landing-page {
		font-size: 16px;
		line-height: 1.5;
		body {
			font-size: 16px;
		}
	}
}
body {
	margin: 0;
	padding: 0;
	font-family: $font-stack;
	color: $black;
}
html,
body {
	min-height: 100%;
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
button,
input[type='button'],
a {
	cursor: pointer;
	transition: color 0.35s, background-color 0.35s, border-color 0.35s,
		box-shadow 0.35s, opacity 0.35s;
}
.card {
	background-color: $white;
	border-radius: 12px;
	padding: 32px;
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.03);
	.icon {
		margin-bottom: 24px;
		img,
		svg {
			max-width: 66px;
			max-height: 66px;
			min-height: 66px;
		}
	}
	.title {
		font-size: 24px;
		margin-bottom: 16px;
		font-weight: 500;
	}
	ul {
		li {
			margin-bottom: 16px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.shadow {
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.03);
}
.d-flex {
	display: flex;
}
.align-items-stretch {
	align-items: stretch;
}
.v-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.h-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
//placeholder CSS
::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $gray-dark;
	font-weight: 300;
	font-size: 0.95rem;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: $gray-dark;
	font-weight: 300;
	font-size: 0.95rem;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: $gray-dark;
	font-weight: 300;
	font-size: 0.95rem;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: $gray-dark;
	font-weight: 300;
	font-size: 0.95rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-stack;
	margin-top: 0;
}
a {
	text-decoration: none;
	color: $purple;
	transition: all 0.2s ease-in-out 0s;
}

.formtext{
	input[type="text"] {
		height: 50px;
		width: 1000px;
		}
		margin-bottom: 16px;

}

.text-center {
	text-align: center;
}

.p-0 {
	padding: 0 !important;
}
.p-32 {
	padding: $p-32;
}
.py-80 {
	padding: 80px 0;
	@media (max-width: 767px) {
		padding: 40px 0;
	}
}
.m-auto {
	margin: 0 auto;
}

// container
.landing-page {
	section {
		overflow: hidden;
		& > div {
			margin: 0 auto;
			padding: 0 24px;
			max-width: 1248px;
			h2 {
				font-size: 48px;
				line-height: 58px;
				margin-bottom: 50px;
				@media (max-width: 767px) {
					margin-bottom: 24px;
					font-size: 30px;
					line-height: 40px;
					text-align: left;
				}
			}
		}
	}
}
// Alternative background
.bg-light {
	background-color: $gray-light;
}

.get-started {
	h2 {
		margin-bottom: 42px;
	}
	p {
		font-size: 24px;
		max-width: 996px;
		margin: 0 auto 56px;
		@media (max-width: 767px) {
			font-size: 20px;
		}
	}
	& > div {
		@media (max-width: 767px) {
			text-align: left;
		}
		.m-auto {
			@media (max-width: 767px) {
				margin: 0;
			}
		}
	}
}
.btn {
	&.btn-cta {
		& + {
			.help-text {
				font-size: 0.85rem;
				margin-top: 16px;
				text-decoration: underline;
				line-height: initial;
			}
		}
	}
}
// code section
.mojoauth-code {
	.card {
		background-color: $black;
		max-width: 800px;
		width: 100%;
		color: $white;
		margin: 0 auto;
		position: relative;
		.copy-btn {
			position: absolute;
			top: 32px;
			right: 32px;
		}
		span {
			color: $yellow;
		}
	}
	pre {
		background-color: transparent !important;
		padding: 0 !important;
		margin: 0;
	}
}



// customization section
.customize {
	.card {
		background-color: $gray-medium;
		display: flex;
		.editor {
			border-radius: 12px 0 0 12px;
			width: 440px;
			background-color: $white;
			.input-group {
				margin-bottom: 15px;
				display: flex;
				flex-direction: column;
				label {
					margin-bottom: 0.25px;
				}
				input,
				select {
					min-height: 42px;
					padding: 0 16px;
					border: 1px solid #ccc;
					border-radius: 4px;
					&:focus,
					&:hover {
						border-color: $purple;
						outline-color: $purple;
					}
				}
			}
		}
		.preview {
			width: calc(100% - 440px);
			border-radius: 0 12px 12px 0;
			.card {
				background-color: $white;
				width: 400px;
				margin: 0 auto;
				padding: 60px 40px;
				.logo {
					margin-bottom: 24px;
					img {
						max-width: 212px;
						height : 400px;
					}
				}
				.title {
					margin-bottom: 8px;
					font-size: 24px;
					line-height: 32px;
					font-weight: 700;
				}
				form {
					margin-top: 56px;
					input[type='text'],
					input[type='button'] {
						padding: 0 16px;
						min-height: 44px;
						border-radius: 4px;
						background-color: $gray-medium;
						width: 100%;
						border: 0;
						margin-bottom: 16px;
					}
					input[type='text'] {
						&:hover,
						&:focus {
							outline-color: $purple;
						}
						&:focus {
							background-color: $white;
						}
					}
					input[type='button'] {
						background-color: $purple;
						color: $white;
						width: 100%;
						margin-bottom: 0;
						&:hover,
						&:focus {
							outline: 0;
							background-color: $purple-dark;
						}
					}
					p {
						color: $gray-dark;
						font-size: 14px;
					}
				}
			}
		}
	}
}

// pricing page css
.pricing-table {
	.card {
		max-width: 480px;
		h2 {
			span {
				color: $purple;
			}
		}
		ul {
			margin-bottom: 50px;
			li {
				display: flex;
				align-items: center;
				svg {
					color: $success;
					margin-right: 0.85rem;
				}
			}
		}
	}
}
.faq {
	.card {
		.title {
			margin-bottom: 0;
		}
	}
	.accordion {
		margin: 0 auto;

		width: 960px;
	}
	.accordion ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.accordion li {
		margin-bottom: 32px;
	}
	.accordion [type='radio'],
	.accordion [type='checkbox'] {
		display: none;
	}
	.accordion label {
		display: block;
		font-size: 1.125rem;
		line-height: 16px;
		font-weight: 600;
		cursor: pointer;
	}
	.accordion ul li label:hover,
	.accordion [type='radio']:checked ~ label,
	.accordion [type='checkbox']:checked ~ label {
		color: black;
	}
	.accordion .content {
		margin-top: 16px;
		overflow: hidden;
		-webkit-transition: all 0.5s ease-out;
		-moz-transition: all 0.5s ease-out;
	}
	.accordion p {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 14px;
		color: #333;
		padding: 15px 10px;
		background-color: #fafafa;
	}
	.accordion p:hover {
		color: red;
		cursor: pointer;
		background-color: #d9d9d9;
	}
	.accordion h3 {
		padding: 0;
		margin: 10px 0;
	}

	.vertical ul li {
		overflow: hidden;
	}

	.vertical [type='radio']:checked ~ label,
	.vertical [type='checkbox']:checked ~ label {
		border-bottom: 0;
	}

	.vertical ul li .content {
		height: 0px;
		border-top: 0;
		transition: all 0.5s ease-out;
	}
	.vertical [type='checkbox']:checked ~ label ~ .content {
		height: 20px;
		margin-top: 16px;
	}
	.vertical [type='checkbox'] ~ .content {
		margin-top: 0;
	}
}

.privacy-policy {
	.plain-content {
		max-width: 894px;
		b {
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 12px;
			margin-top: 48px;
			display: block;
		}
	}
}

.hero {
	h1 {
		font-size: 60px;
		margin: 0 0 48px;
		line-height: 72px;
		@media (max-width: 767px) {
			font-size: 32px;
			line-height: 40px;
			margin: 0 0 48px;
		}
	}
	.description {
		@media (min-width: 769px) {
			// padding-right: 150px;
			padding-right: 0px;
		}
		p {
			font-size: 24px;
			line-height: 34px;
			margin-bottom: 48px;
			@media (max-width: 767px) {
				font-size: 20px;
			}
			span {
				font-size: 16px;
			}
		}
	}
	&.inner {
		.description {
			padding-right: 0;
		}
	}
	.vector {
		display: none;
		@media (min-width: 369px) {
			display: block;
			// height: auto;
			// width: auto;
			
		}
		// border: #000000 5px;
		padding-left: 90px;
		
	}
	
	.grid-60-40 {
		& > div {
			@media (max-width: 368px) {
				width: 100%;
				margin-right: 0;
			}
		}
	}
}
