@import '../../styles/variable-dashboard.scss';

.settings {
	form {
		width: 67%;
	}
}
.close-account {
	width: 67%;
}
