.footer {
	padding: 48px 0;
	border-top: 1px solid $gray-lightest;
	// to center align as privacy links has been removed fot now
	display: flex;
	a {
		&:hover {
			color: $purple-dark;
		}
	}
}
