@import '../../styles/variable.scss';
header {
	min-height: 99px;
	background-color: $white;
	border-bottom: 1px solid $gray-lightest;
	box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.06);
	padding: 0 32px;
	display: flex;
	align-items: center;
	position: fixed;
	width: 100%;
	z-index: 4;
	.account-info {
		flex: 1 1;
		display: flex;
		position: relative;
		padding-left: 100px;
		justify-content: space-between;
		.ui {
			&.dropdown {
				.menu {
					top: calc(100% + 20px);
					.active {
						&.item {
							font-weight: 400;
							.text {
								color: $purple;
							}
						}
					}
					.description {
						color: $gray-dark;
						margin-top: 0.5rem !important;
						font-size: 0.875rem;
						text-transform: uppercase;
						letter-spacing: 0.5px;
					}
				}
			}
		}
		.project {
			.dropdown {
				display: flex;
				align-items: flex-end;
				min-width: 100px;
				.menu {
					.item {
						display: flex;
						flex-direction: column-reverse;
					}
				}
				.divider {
					&.text {
						padding: 1rem 0 0 0;
						&:before {
							content: 'MY PROJECTS';
							position: absolute;
							top: 0;
							left: 0;
							color: $gray-dark;
							font-size: 10px;
							font-weight: 400;
							letter-spacing: 0.5px;
						}
					}
				}
			}
		}
		.account {
			display: flex;
			align-items: center;
			.first-name {
				padding: 0 0 0 0.5rem;
			}
			.ui {
				&.dropdown {
					.menu {
						left: inherit;
						right: 0;
						.item {
							.text {
								line-height: 1.2em;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
								max-width: 200px;
							}
						}
					}
				}
			}
			.user {
				&.icon {
					width: 42px;
					height: 42px;
					background-color: $bg-color;
					font-size: 1.125rem;
					border-radius: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 0.5rem;
					&:before {
						content: '\e90a';
						font-family: 'mojoauth';
					}
					& ~ .dropdown {
						text-transform: capitalize;
						.icon {
							display: none;
						}
					}
				}
			}
		}
	}
}
