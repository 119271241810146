.header {
	width: 100%;
	min-height: 100px;
	border-bottom: 1px solid $gray-lightest;
	@media (max-width: 767px) {
		padding: 24px 0;
	}
	& > div {
		width: 100%;
	}
	.logo {
		img {
			// max-width: 200px;
			// max-height: 62px;
			// width: 200px;
			max-width: 70px;
    		max-height: 70px;
    		width: 70px;
			@media (max-width: 75px) {
				width: 100px;
			}
		}
	}
	nav {
		ul {
			li {
				a:not(.btn) {
					height: 100px;
					display: flex;
					align-items: center;
					color: $black;
					padding: 0 1.5rem;
					position: relative;
					font-weight: 500;
					&:hover {
						color: $purple;
						&:before {
							transform: scaleX(1);
							transform-origin: 0 0;
						}
					}
					&:before {
						transition: transform 0.35s cubic-bezier(0.3, 0.86, 0.36, 0.95);
						content: '';
						position: absolute;
						left: 1.5rem;
						right: 1.5rem;
						bottom: 1.85rem;
						transform: scaleX(0);
						transform-origin: 100% 100%;
						height: 1px;
						background-color: currentColor;
					}
				}
			}
			&.nav-right {
				@media (max-width: 767px) {
					margin-left: 24px;
				}
			}
		}
	}
}
