@import '../../styles/variable-dashboard.scss';
.branding {
	.grid-60-40 {
		flex-direction: row-reverse;
		& > div {
			margin-right: 0;
			&:nth-child(2n) {
				margin-right: 24px;
			}
		}
	}
	.branding-form {
		padding: 1.5rem 0 1.5rem 1.5rem;
		.btn-wrap {
			padding: 0 1rem 1rem;
		}
	}
	.tab-wrap {
		background-color: $gray-10;
		border-radius: 0.25rem;
	}
	.preview {
		background-color: $bg-color;

		.header {
			min-height: 66px;
			background-color: $white;
			font-weight: 500;
			color: $black;
			padding: 0 1.5rem;
			display: flex;
			align-items: center;
			border-left: 2px solid $bg-color;
			& > div {
				width: auto;
			}
			.ui {
				padding-left: 1rem;
				&.form {
					.fields {
						margin: 0;
					}
				}
			}
		}
		.main {
			&.authentication {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $bg-color;
				margin-top: 60px;
				padding-bottom: 60px;
				.ui {
					font-family: inherit;
					&.form {
						font-family: inherit;
						.field {
							font-family: inherit;
							margin-bottom: 1.25rem;
							.btn-wrap {
								.button {
									width: 100%;
									font-family: inherit;
								}
							}
						}
					}
				}
				.card {
					font-family: inherit;
					border-radius: 0.75rem;
					width: 400px;
					margin: 0 auto;
					padding: 60px 40px;
					min-height: 520px;
					.logo {
						margin-bottom: 24px;
						img {
							max-width: 212px;
						}
					}
					.title {
						font-family: inherit;
						margin-bottom: 8px;
						font-size: 24px;
						line-height: 32px;
						font-weight: 700;
					}
					form {
						margin-top: 56px;
						&.ui.form .field input[type='text'] {
							font-family: inherit;
						}

						p {
							color: $gray-medium;
							font-size: 14px;
							font-family: inherit;
						}
					}
				}
				.v-center {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				img {
					max-width: 100%;
				}
				.ui {
					&.button {
						color: $white;
						background-color: $purple;
						&:hover {
							background-color: $purple-dark;
						}
					}
				}
				::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					font-family: inherit;
				}
				::-moz-placeholder {
					/* Firefox 19+ */
					font-family: inherit;
				}
				:-ms-input-placeholder {
					/* IE 10+ */
					font-family: inherit;
				}
				:-moz-placeholder {
					/* Firefox 18- */
					font-family: inherit;
				}
			}
		}
	}
}
