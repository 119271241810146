@import '../../styles/variable-dashboard.scss';
@mixin allcenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

.project-card {
	min-height: 215px;
	&.add-new-card {
		@include allcenter;
		.btn-ghost {
			font-weight: 500;
			border-right: 0.25rem;
			min-height: 48px;
			font-size: 0.938rem;
			color: $purple;
			cursor: pointer;
			@include allcenter;
			&:hover {
				color: $purple-dark;
			}
			i {
				margin-right: 0.5rem;
				font-size: 1.5rem;
			}
		}
	}
	.project-thumbnail {
		background-color: $gray-10;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100px;
		.icon {
			margin-bottom: 0;
			img {
				max-width: 56px;
				width: 56px;
				display: block;
			}
		}
	}
	.project-content {
		padding: 1.5rem;
		.title {
			font-size: 1.125rem;
			margin-bottom: 0.5rem;
			font-weight: 600;
		}
		.url {
			color: $gray-dark;
			font-size: 0.875rem;
			margin-bottom: 1.5rem;
		}
		.tag {
			background-color: $yellow;
			color: $black;
			font-size: 0.75rem;
			padding: 0.15rem 1rem;
			border-radius: 20px;
			text-transform: uppercase;
			width: fit-content;
			font-weight: 500;
		}
	}
	.project-action {
		a {
			border-top: 1px solid $gray-lightest;
			padding: 1.25rem;
			font-size: 0.938rem;
			font-weight: 500;
			border-radius: 0 0 0.5rem 0.5rem;

			@include allcenter;
			i {
				margin-left: 0.5rem;
				font-size: 1.25rem;
			}
			&:hover {
				background-color: $gray-10;
				color: $purple-dark;
			}
		}
	}
}
.main {
	.page-container {
		&.add-project {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $bg-color;
			height: 100vh;
			min-height: 100vh;
			padding: 0;
			& > div {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.page-container-inner {
				width: 400px;
				margin: 0 auto;
				flex: initial;
			}
			.card {
				.title {
					margin-bottom: 8px;
					font-size: 24px;
					line-height: 32px;
					font-weight: 700;
				}
				.subtitle {
					margin-bottom: 40px;
				}
				.full-width {
					width: 100%;
				}
			}
		}
	}
}
