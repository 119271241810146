.grid-60-40,
.grid-33,
.grid-50 {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: inherit;
	-ms-flex-pack: inherit;
	justify-content: inherit;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.grid-60-40 {
	& > div {
		display: inline-block;
		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 24px;
		}
		@media (min-width: 769px) {
			margin-right: 24px;
			width: calc(60% - (24px * 1 / 2));
		}
		&:nth-child(2n) {
			@media (min-width: 769px) {
				margin-right: 0;
				width: calc(40% - (24px * 1 / 2));
			}
		}
	}
}
.grid-50 {
	& > div {
		display: inline-block;
		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 24px;
			margin-right: 0;
		}
		@media (min-width: 769px) {
			margin-right: 24px;
			width: calc(50% - (24px * 2 / 2));
		}
		&:nth-child(2n) {
			@media (min-width: 769px) {
				margin-right: 0;
			}
		}
	}
}
.grid-33 {
	& > div {
		display: inline-block;
		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 24px;
			margin-right: 0;
		}
		@media (min-width: 769px) {
			margin-right: 24px;
			width: calc(33% - (24px * 2 / 3));
		}
		&:nth-child(3n) {
			@media (min-width: 769px) {
				margin-right: 0;
			}
		}
	}
}
.grid-20 {
	& > div {
		display: inline-block;
		margin-bottom: 24px;
		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 24px;
			margin-right: 0;
		}
		@media (min-width: 769px) {
			margin-right: 24px;
			width: calc(20% - (24px * 4 / 5));
		}
		&:nth-child(5n) {
			@media (min-width: 769px) {
				margin-right: 0;
			}
		}
	}
}
