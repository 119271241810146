@import 'variable-dashboard.scss';
@import 'grid.scss';

body:not(#branding-preview) {
	font-family: $font-stack;
}
html {
	font-size: 16px;
	line-height: 1.5;
	&:not(.landing-page) {
		body {
			background-color: $bg-color;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $font-stack;
		}
	}
}
body {
	font-size: 16px;
	line-height: 1.5;
	font-family: $font-stack;
}
a {
	color: $purple;
	&:hover {
		color: $purple-dark;
	}
}
.justify-between {
	justify-content: space-between;
}
.mb-sm {
	margin-bottom: 2rem !important;
}
.ui {
	font-family: $font-stack;
	&.menu {
		font-family: $font-stack;
	}
	&.button {
		&.mini {
			&.primary {
				background-color: $purple;
				color: $white;

				&:hover,
				&:focus {
					background-color: $purple-dark;
				}
			}
		}
	}
	&.button:not(.mini) {
		transition: color 0.35s, background-color 0.35s, border-color 0.35s,
			box-shadow 0.35s, opacity 0.35s;
		font-family: $font-stack;
		font-weight: 500;
		border-right: 0.25rem;
		min-height: 48px;
		font-size: 0.938rem;
		justify-content: center;
		margin: 0;
		min-width: 112px;
		display: flex;
		align-items: center;
		& ~ .button {
			margin-left: 1.125rem;
		}
		&.primary {
			background-color: $purple;
			color: $white;
			i {
				font-size: 1.5rem;
				&.left {
					margin-left: 0.5rem;
				}
				&.right {
					margin-right: 0.5rem;
				}
			}
			&:hover,
			&:focus {
				background-color: $purple-dark;
			}
		}
		&.basic {
			font-weight: 500;
			color: $black !important;
			box-shadow: none;
			&:hover,
			&:focus,
			&:active {
				box-shadow: none;
				background-color: $bg-color !important;
			}
			&.purple {
				border-color: $purple !important;
				color: $purple !important;
				&:hover {
					border-color: $purple-dark !important;
					color: $purple-dark !important;
				}
			}
		}
		&.red {
			background-color: $error;
		}
	}
	&.form {
		.field {
			margin-bottom: 1.25rem;
			& > label {
				color: $black;
				font-weight: 400;
				margin-bottom: 0.5rem;
				font-size: 1rem;
				& + .help-text {
					font-size: 0.75rem;
					margin-bottom: 0.5rem;
					color: $gray-dark;
					display: block;
					line-height: 1rem;
				}
			}
			input:not([type]),
			input[type='date'],
			input[type='datetime-local'],
			input[type='email'],
			input[type='file'],
			input[type='number'],
			input[type='password'],
			input[type='search'],
			input[type='tel'],
			input[type='text'],
			input[type='time'],
			input[type='url'],
			.ui.selection.dropdown {
				background-color: $input-bg;
				font-family: $font-stack;
				border-color: $input-bg;
				min-height: 48px;
				font-size: 0.875rem;
				color: $black;
				&:focus {
					border-color: $purple;
					background-color: $white;
				}
				&:hover {
					border-color: $purple;
				}
			}
		}
		//placeholder CSS
		::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			color: $gray-dark;
			font-weight: 300;
			font-size: 0.95rem;
		}
		::-moz-placeholder {
			/* Firefox 19+ */
			color: $gray-dark;
			font-weight: 300;
			font-size: 0.95rem;
		}
		:-ms-input-placeholder {
			/* IE 10+ */
			color: $gray-dark;
			font-weight: 300;
			font-size: 0.95rem;
		}
		:-moz-placeholder {
			/* Firefox 18- */
			color: $gray-dark;
			font-weight: 300;
			font-size: 0.95rem;
		}
	}
	&.table {
		border: 0;
		border-radius: 0;
		color: $black;
		thead {
			tr {
				&:first-child {
					th {
						&:first-child,
						&:last-child {
							border: 0;
							border-radius: 0;
						}
					}
				}
			}
			th {
				background-color: $gray-medium;
				color: $white;
				padding: 19px;
				font-weight: 500;
				font-size: 1rem;
			}
		}
		tbody {
			tr {
				td {
					padding: 27px 19px;
					border: 0;
					border-radius: 0;
				}
			}
		}
		&.striped {
			tbody {
				tr {
					&:nth-child(2n) {
						background-color: $bg-color;
					}
				}
			}
			& > tr {
				&:nth-child(2n) {
					background-color: $bg-color;
				}
			}
		}
	}
	&.dropdown {
		display: flex;
		align-items: center;
		& > .text {
			font-size: 1rem;
			font-weight: 500;
			& ~ .icon {
				font-size: 1.25rem;
				margin-left: 0.5rem;
				&:before {
					content: '\e90b';
					font-family: 'mojoauth';
				}
			}
		}
		.menu {
			box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.16);
			min-width: 240px;
			& > .item {
				span {
					width: 100%;
					margin: 0 !important;
					display: block;
					float: none !important;
				}
			}
		}
		a {
			font-size: 1rem;
			font-weight: 500;
			display: flex;
			align-items: center;
			min-height: 52px;
			padding: 1rem 1rem 0.5rem !important;
			&:hover {
				color: $purple-dark;
			}
			i {
				font-size: 1.5rem;
				margin-right: 0.5rem;
			}
		}
		.divider {
			& ~ a {
				padding: 0.5rem 1rem 1rem !important;
			}
		}
		.button {
			margin: 0;
			width: 100%;
			display: flex;
			align-items: center;
			i {
				font-size: 1.5rem;
			}
		}
	}
	&.dimmer {
		background-color: rgba(0, 0, 0, 0.36);
	}
	&.modal {
		border-radius: 0.75rem;
		.header {
			font-family: $font-stack;
			font-weight: 600;
			font-size: 1.25rem;
			min-height: 66px;
			border-bottom: 1px solid $gray-lightest;
		}
		.close {
			font-size: 1.75em;
			color: $black;
			top: 8px;
			right: 10px;
			&:before {
				content: '\e902';
				font-family: 'mojoauth';
			}
			&:first-child + * {
				border-top-left-radius: 0.75rem;
				border-top-right-radius: 0.75rem;
			}
		}
		& > .actions {
			background-color: $white;
			border-top-color: $white;
			padding: 1.5rem;
			display: flex;
			justify-content: flex-end;
		}
		& > :last-child {
			border-bottom-left-radius: 0.75rem;
			border-bottom-right-radius: 0.75rem;
		}
	}
	&.selection {
		&.dropdown {
			line-height: 1.75em;
			.text {
				font-size: 0.875rem;
			}
			.menu {
				.active {
					&.item {
						font-weight: 500;
					}
				}
			}
			& > .dropdown {
				&.icon {
					padding: 12px 0;
					margin: 0;
					top: 0;
					line-height: normal;
				}
			}
			&:not(.button) > .default.text {
				color: $gray-medium;
				font-weight: normal;
			}
		}
		&.active {
			&.dropdown {
				.menu {
					border-color: $purple;
					&:hover {
						border-color: $purple;
					}
				}
			}
		}
	}
	&.secondary {
		&.pointing {
			&.menu {
				border-bottom: 1px solid $gray-lightest;
				.item {
					margin: 0 0 -1px;
					flex: auto;
					justify-content: center;
					color: $black;
				}
				a {
					&.item {
						border-bottom-width: 1px;
						margin: 0;
						padding: 1.25em 1.14285714em;
						&:hover {
							color: $black;
						}
						&:active {
							border-color: $black;
						}
					}
				}
				.active {
					&.item {
						color: $purple;
						font-weight: 400;
						border-bottom-color: $purple;
						&:hover {
							color: $purple;
							border-color: $purple;
						}
					}
				}
			}
		}
	}
	&.tab {
		&.active {
			box-shadow: none;
			background-color: transparent;
			border: 0;
		}
	}
}
.p-0 {
	padding: 0 !important;
}
.my-32 {
	margin: 2rem 0;
}
hr {
	border-width: 1px 0 0 0;
	border-color: $gray-lightest;
	border-style: solid;
}
.btn-wrap {
	width: auto;
	display: flex;
	justify-content: flex-end;
}
.main {
	.sidebar {
		& ~ .page-container {
			padding-left: 300px;
		}
	}
	.page-container {
		flex: 1 1;
		display: flex;
		z-index: 2;
		position: relative;
		padding-top: 100px;
		.page-container-inner {
			margin: 32px;
			padding: 0;
			flex: 1 1;
			.page-header {
				padding: 32px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #dde1e6;
				& > div {
					&:first-child {
						width: 56%;
					}
					&:nth-child(2) {
						display: flex;
						justify-content: flex-end;
					}
				}
				h1 {
					font-size: 24px;
					line-height: 32px;
					font-weight: 600;
					margin: 0 0 0.25rem;
				}
				p {
					font-size: 1rem;
					color: $gray-dark;
					margin-top: 0;
				}
			}
			.page-content {
				padding: 32px;
			}
		}
		.card {
			background-color: $white;
			box-shadow: none;
			border-radius: 0;
			&.p-32 {
				padding: 32px;
			}
			&.shadow {
				box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.16);
			}
			&.radius-sm {
				border-radius: 0.5rem;
			}
			h2 {
				font-size: 2rem;
				font-weight: 700;
				margin-bottom: 0.25rem;
			}
			h3 {
				font-size: 1.125rem;
				font-weight: 600;
				margin-bottom: 2rem;
			}
			p {
				font-size: 1rem;
				color: $gray-dark;
			}
		}
	}
}
.grid-50 {
	& > div {
		display: inline-block;
		margin-right: 24px;
		width: calc(50% - (24px / 2));
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
}
.grid-33 {
	& > div {
		display: inline-block;
		margin-right: 24px;
		width: calc(33.33% - (24px * 2 / 3));
		margin-bottom: 24px;
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}
.grid-25 {
	display: flex;
	flex-wrap: wrap;
	& > div {
		display: inline-block;
		margin-right: 24px;
		width: calc(25% - (24px * 3 / 4));
		margin-bottom: 24px;
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
}

// mixin CSS
@mixin allcenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

// Preloader
#preloader {
	position: absolute;
	left: 280px;
	right: 280px;
	top: 60px;
	background-image: linear-gradient(45deg, #ffffff, #ffffff);
	z-index: 9999999;
	transition: 300ms ease-in-out;

	&.fullscreen {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		#status {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	#status {
		.spinner {
			width: 40px;
			height: 40px;
			position: relative;
			margin: 100px auto;

			.double-bounce1,
			.double-bounce2 {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #2f55d4;
				opacity: 0.6;
				position: absolute;
				top: 0;
				left: 0;
				animation: sk-bounce 2s infinite ease-in-out;
			}
			.double-bounce2 {
				animation-delay: -1s;
			}
		}
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.text-center {
	text-align: center;
}

// toast UI
.Toastify__toast {
	&--info {
		background: $success;
	}
	&--error {
		background: $error;
	}
	&--warning {
		background: $warning;
	}
}

// custom input CSS
.custom-input {
	width: 100%;
	background-color: $input-bg;
	font-family: $font-stack;
	border: 1px solid $input-bg;
	min-height: 48px;
	font-size: 0.875rem;
	color: $black;
	line-height: 1.21428571em;
	padding: 0.67857143em 1em;
	border-radius: 0.28571429rem;
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	span {
		margin-left: 0.5rem;
	}
	&:hover {
		border-color: $purple;
	}
}

.error {
	color: $error;
	margin: 8px 0 0;
	text-align: left;
	display: block;
	font-size: 0.85rem;
}
