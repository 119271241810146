@import '../../styles/variable-dashboard.scss';

.statics {
	display: flex;
}
.api-credentials {
	form,
	.apikey {
		width: 67%;
	}
	.apikey {
		.button {
			margin-bottom: 0;
		}
	}
}
