@import '../../styles/variable.scss';
.sidebar {
	position: fixed;
	left: 0;
	top: 100px;
	height: 100vh;
	background-color: $white;
	width: 300px;
	display: flex;
	overflow: hidden;
	min-width: 300px;
	flex: 0 0 300px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	flex-direction: column;
	z-index: 3;
	ul {
		margin-top: 32px;
		list-style-type: none;
		padding-left: 0;
		li {
			a {
				font-size: 1rem;
				font-weight: 500;
				display: flex;
				align-items: center;
				padding: 0 32px;
				min-height: 56px;
				color: $black;
				&:hover,
				&.active {
					color: $purple;
					background-color: $bg-color;
				}
				i {
					color: $purple;
					font-size: 1.25rem;
					margin-right: 1.5rem;
					&:last-child {
						color: $black;
						margin-right: 0;
						margin-left: 0.875rem;
						font-size: 1.25rem;
					}
				}
			}
		}
	}
}
