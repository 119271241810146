// Font Family
$font-stack: 'Inter', sans-serif;

// Brand tones
$yellow: #ffe100;
$purple: #5e00cf;
$purple-dark: #38007c;
$purple-10: #f6f2ff;

$black: #393939;
$white: #ffffff;

$gray-dark: #6f6f6f;
$gray-medium: #8d8d8d;
$gray-light: #f9f9f9;
$gray-lightest: #e8e9ea;
$gray-10: #fcfcfc;
$bg-color: #f4f4f4;

// notification alerts colors
$success: #39b55a;
$warning: #f1a321;
$error: #e61728;
$input-bg: #efefef;
