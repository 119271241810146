// Font Family
$font-stack: 'Inter', sans-serif;
$heading-font-stack: 'Maven Pro', sans-serif;

// Brand tones
$yellow: #ffe100;
$yellow-dark: #f2d70d;
$purple: #5e00cf;
$purple-dark: #38007c;
$green : #97ff89;
$green-dark : #79CC6E;
// $pink : #ff1a8c;

$black: #393939;
$white: #ffffff;

$light-black: #6f6f6f;
$gray-lightest: #e8e9ea;
$gray-light: #f9f9f9;
$gray-medium: #e7e7e7;
$gray-dark: #757a7e;

$bg-color: #f4f4f4;

// notification alerts colors
$success: #39b55a;
$warning: #f1a321;
$error: #e61728;

// spacing modifier variable.
$space-none: 0;
$space-x-sm: 16px;
$space-sm: 24px;
$space-md: 48px;
$space-lg: 96px;
$space-x-lg: 128px;
$space-auto: auto;

$p-32: 32px;
